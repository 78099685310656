<template>
    <input ref="inputRef" :value="formattedValue" :disabled="disabled" :placeholder="placeholder" :class="customClass" @focus="emitFocus" @blur="emitBlur" @keydown="emitKeyPressed" />
</template>

<script>
import { watch } from "@vue/composition-api";
import { useCurrencyInput } from "vue-currency-input";

export default {
    name: "CurrencyInput",

    props: {
        value: [String, Number],
        disabled: Boolean,
        placeholder: String,
        customClass: String,
        precision: { type: Number, default: 2 },
        min: { type: Number, default: -99999999 },
        max: { type: Number, default: 99999999 },
    },

    setup(props) {
        const { formattedValue, inputRef, setValue } = useCurrencyInput({
            locale: "sv-SE",
            currency: "EUR",
            currencyDisplay: "hidden",
            precision: props.precision,
            hideCurrencySymbolOnFocus: true,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: true,
            autoDecimalDigits: false,
            exportValueAsInteger: false,
            autoSign: true,
            useGrouping: true,
            valueRange: {
                min: props.min,
                max: props.max,
            },
        });

        watch(
            () => props.value,
            value => {
                setValue(value);
            },
        );

        return { inputRef, formattedValue };
    },

    watch: {
        value() {
            this.$emit("keyUp");
        },
    },

    methods: {
        emitFocus() {
            this.$emit("focus");
        },

        emitBlur() {
            this.$emit("blur");
        },

        emitKeyPressed(e) {
            this.$emit("keyPressed", e);
        },
    },
};
</script>
<style scoped>
input:disabled {
    background: #fcfcfd;
}
input {
    height: 32px;
    font-size: 13px;
    padding-left: 15px !important;
    transition: all ease 250ms;
    border-color: #dcdfe6;
}
input:focus {
    outline: none;
    border: solid #415770 1px;
}
</style>
